import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const march = this.props.data.march.map(function (march) {
      return (
        <div key={march.school}>
          <h3>{march.school}</h3>
    <br></br>
        </div>
      );
    });

    const q1 = this.props.data.q1.map(function (q1) {
      return (
        <div key={q1.company}>
          <h3>{q1.company}</h3>
          <p className="info">
            {q1.title}
          <em className="date">{q1.years}</em>
          </p>
          <p>{q1.description}</p>
        </div>
      );
    });

    const q2 = this.props.data.q2.map(function (q2) {
      return (
        <div key={q2.company}>
          <h3>{q2.company}</h3>
          <p className="info">
            {q2.title}
 <em className="date">{q2.years}</em>
          </p>
          <p>{q2.description}</p>
        </div>
      );
    });



    const q3 = this.props.data.q3.map(function (q3) {
      return (
        <div key={q3.company}>
          <h3>{q3.company}</h3>
          <p className="info">
            {q3.title}
 <em className="date">{q3.years}</em>
          </p>
          <p>{q3.description}</p>
        </div>
      );
    });


    const q4 = this.props.data.q4.map(function (q4) {
      return (
        <div key={q4.company}>
          <h3>{q4.company}</h3>
          <p className="info">
            {q4.title}
 <em className="date">{q4.years}</em>
          </p>
          <p>{q4.description}</p>
        </div>
      );
    });



    const beyond = this.props.data.beyond.map(function (beyond) {
      return (
        <div key={beyond.company}>
          <h3>{beyond.company}</h3>
          <p className="info">
            {beyond.title}
           <em className="date">{beyond.years}</em>
          </p>
          <p>{beyond.description}</p>
        </div>
      );
    });

    const skills = this.props.data.skills.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;

      return (
        <li key={skills.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });

    return (
      <section id="roadmap">
 
        
      </section>
    );
  }
}

export default Resume;
