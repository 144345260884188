import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row " >
            <div className="three columns">
              <img
                className="profile-pic"
                src="logo.png"
                alt="Everburn"
              />
            </div>
            <div className="nine columns main-col">
            <h2>🔥 NEWS & ANNOUNCEMENTS 🔥</h2>
      
      <p>➡️ Feb 2024 - Everbuild updates Roadmap and starts building two new utility projects to drive volume into EVB & FLD Ecosystems, updated WP to follow soon! </p>			
      <p>➡️ Feb 2024 - Everburn Updates Reflection System to Active Holders! <a href="https://medium.com/@everburntoken/everburn-update-feb-2024-e7fb346dccc8">Read here</a></p>
      <p>➡️ October 2023 - Everburn Adds FLD/EVB Pair on TraderJoe, is added to Ninety1 Labs Approved FLD Staking emission Pair! </p>
      <p>➡️ Sept 2023 - Everburn Burns 10% of the supply! </p>
      <p>➡️ June 2023 - Everbuild Joins Ninety 1 Labs Eco System and Team! </p>
      <p>➡️ June 2023 - Everburn starts reflecting FOLD from Ninety1 Labs! </p>
      <p>➡️ May 2023 - Everbuild becomes stand alone Web3 Talent Marketplace for Devs, NFT Artists, UI/UX Designers and Crypto Jobs. </p>
      <p>➡️ May 2023 - Everburn Commission System from Everbuild Scrapped due to regulatory issues. </p>
      <p>➡️ March 2023 - ECT Payment Dashboard in closed beta! </p>
			<p>➡️ Feb 2023 - Everbuild / Everburn Discord Revamped & Upgraded! </p>
			<p>➡️ Jan 2023 - Everbuild Website Marketplace in beta ! </p>
      <p>➡️ April 2022 - Everburn Token Reaches 4M MC and 500k Daily Trade Volume! </p>
      <p>➡️ Mar 2022 - Everburn Launched on Avalanche! </p>
			
			

              <h2>🔥 WHAT IS EVERBURN? 🔥</h2>

              <p>Everburn is a Hyper-Deflationary reflection token, rapidly burning supply on every buy and sale.
                The burn process is percentage-based, so as supply diminishes, the burn rate slows down, and every holders' share percentage of the total supply increases. This deflation process dynamically determines the amount of reward token (currently USDT) that the smart-contract reflects to the holders in real-time on every sale.</p>


              <p>The problem that all reflection tokens face for long-term sustainability is that they require consistent trade volume to stay relevant. Once the trade volume drops, most holders sell their tokens and abandon the project. There is no correlation between the trade volume that generates the reflection rewards and the token's speculative spot price. They work independently and against each other to ultimately send the token into inevitable failure.</p>

              <p>This is where Everburn comes in. The burn mechanics causes buy pressure as the supply rapidly decreases, and because the reflection tax is back load on only the sell side, it deters sell pressure, rewarding holders when people do. The Primary focus is burning supply, USDT reflections is a by product of the premise.</p>
            
			<h2>🔥 WHAT IS EVERBUILD? 🔥</h2>
			<p>EverBuild has two sides. It is a Crypto freelance marketplace as well as a crypto job board for longer-term positions in Web3 and the crypto blockchain industry. </p>
			<p>The online freelance marketplace is a marketplace for crypto-related tasks, jobs, and gigs. Everbuild is a marketplace for everything crypto. It connects project builders and crypto businesses with talent in the crypto industry. The talent can include developers, designers, consultants, marketing professionals, community building experts, exchange listing contacts, crypto lawyers, accountants, professionals, and more. Think about Fiverr/UpWork with a niche specific to the blockchain space. Everything a blockchain business needs to build out in one place and a place for professionals to solicit their services.</p>
			<p>The crypto landscape is very different from your traditional corporate environment. Everbuild is a niche talent exchange marketplace that is unique in the space. Crypto follows a unique set of rules, and finding talent who understands this mindset is no easy task; until now. A specific criterion of knowledge is a mandatory prerequisite to being a cryptocurrency professional, unlike mainstream money markets, software development houses, and marketing in traditional markets.</p>
			<p>Everbuild is also a crypto job board. A place where candidates can submit their resumes or apply to job opportunities by prospective employers. It's a place employers and crypto organizations can post job opportunities and find talent to fill their required job vacancies.</p>
			<p>Whether a project is in the market for a more immediate and short-term task to be completed or is looking to fill a full-time position in its organization, Everbuild has the resources and talent required to provide the professional assistance they need for the request.  </p>


			
			

              <div className="row">

                <div className="columns download">
                  <p>
                    <a href={resumeDownload} className="button" target="_blank">
                      <i className="fa fa-download"></i>Download Whitepaper
                    </a>
                  </p>
                </div>
     
              </div>





            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
