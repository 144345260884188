import React, { Component } from "react";

import Fade from "react-reveal";





const bckgnd = {
  width: '100%',
  backgroundImage: `url("images/background.jpg")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'

};


class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const trade = this.props.data.trade;
    const dashboard = this.props.data.dashboard;
    const name = this.props.data.name;
    const description = this.props.data.description;





    return (
      <header id="home" style={bckgnd}>




        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a href="https://app.everburn.io/" target="_blank" rel="noopener noreferrer">
                Dashboard
              </a>
            </li>

            <li>
              <a href="https://docs.everburn.io" target="_blank" rel="noopener noreferrer">
                Whitepaper
              </a>
            </li>
            <li>
              <a href="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xebe2eae72D6eAA44A3bCA32cFDF81D3A687917c2#/" target="_blank" rel="noopener noreferrer">
                Trade
              </a>
            </li>


            <li>
              <a href="https://dexscreener.com/avalanche/0xebe2eae72D6eAA44A3bCA32cFDF81D3A687917c2" target="_blank" rel="noopener noreferrer">
                Dex Chart
              </a>
            </li>


            <li>
              <a className="smoothscroll" href="#roadmap">
                Roadmap
              </a>
            </li>



            <li>
              <a className="smoothscroll" href="#contact">
                Collaborate
              </a>
            </li>




          </ul>
        </nav>

        <div className="row banner" >
          <div className="banner-text">
            <Fade bottom>
              <img
                className="profile-pic"
                src="logo.png"
                alt="Everburn"
              /><h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href={trade} className="button btn project-btn">
                  Trade
                </a>
                <a href={dashboard} className="button btn github-btn">
                  Dashboard
                </a>
              </ul>
            </Fade>

          </div>
          <div className="row banner">

          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>

      </header >
    );
  }
}

export default Header;
